import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const huessen: FC = () => {
  const titleImage = "_DSC2534.jpg"
  const image1 = "_DSC2461.jpg"
  const image2 = "_DSC2467.jpg"
  const image3 = "_DSC2476.jpg"
  const image4 = "_DSC2505.jpg"
  const image5 = "_DSC2516.jpg"
  const image6 = "_DSC2527.jpg"
  const image7 = "_DSC2529.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Huessen/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Hüssen 2022
          <h5 className="single-project-text">
            Ai margini del nucleo di Ropiana a Mergoscia il rustico Hüssen era
            stato ristrutturato impeccabilmente dal collega Hanspeter Wespi che
            è recentemente andato in pensione. Su richeista dei proprietari e
            con piacere ho potuto riprendere il suo lavoro apportando delle
            modifiche alla veranda esterna, tipica del paese, e di alcune
            disposizioni al piano inferiore. Il grande tema dell’intervento è
            stato quello di riuscire a rendere conforme la tradizionale
            tipologia della veranda con le norme sui parapetti UPI e SIA.
            Partendo dal fatto che un muretto alto 65cm non è scalabile, abbiamo
            preso questa misura apportando esternamente due stanghe di castagno
            esattamente come si faceva due secoli fa. Riprendendo il concetto
            storico della semplicità dei dettagli esecutivi abbiamo applicato
            dei vetri scorrevoli senza telaio e con meccanismi a vista. Al piano
            inferiore è stato creato un nuovo servizio igienico con doccia, le
            porte interne abbiamo deciso di intonacarle per integrarle nella
            parete esistente. Abbiamo inoltre costruito un mobile da bagno in
            castagno.
          </h5>
        </h3>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Huessen/" + image2}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Huessen/" + image1}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Huessen/" + image3}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Huessen/" + image7}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Huessen/" + image4}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Huessen/" + image5}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Huessen/" + image6}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default huessen
